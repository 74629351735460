<template>
  <Transition name="fade" appear>
  
    <div v-if="showDash" class="px-2 sm:px-6 lg:px-4 text-t3 relative">
      <div class="flex w-full items-center justify-between pb-3 mb-3 border-b">

        <h1 class="text-t3 text-3xl font-bold mr-auto">ELP Dashboard</h1>
        <div v-if="isAssessor && (scopeCheck('view_operational_dashboard') || scopeCheck('view_finance_dashboard'))" class="flex gap-2 text-t3  justify-end">
          <button v-if="isAssessor" class="flex gap-2 items-center p-3 border border-t3 rounded-md  hover:bg-t3-secondary hover:border-t3-secondary hover:text-white" :class="{'text-white border-t3-secondary bg-t3-secondary':dashboard==='assessor'}" @click="dashboard = 'assessor'">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
            Assessor View
          </button>
          <button v-if="scopeCheck('view_operational_dashboard') || scopeCheck('view_finance_dashboard')" class="flex gap-2 items-center p-3 border border-t3 rounded-md  hover:bg-t3-secondary hover:border-t3-secondary hover:text-white" :class="{'text-white border-t3-secondary bg-t3-secondary':dashboard==='operational'}" @click="dashboard = 'operational'">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
  </svg>
  
            Operational View
          </button>
        </div>
      </div>
      <div class="relative">

        <Transition name="slide-fade" appear>
        <div v-if="dashboard==='operational'" class="absolute top-0 left-0 right-0 bottom-16">
    
        <!-- <div class="mt-7">
          <div class="grid grid-cols-1 md:grid-cols-4 xl:grid-cols-5 px-4 xl:p-0 gap-y-4 md:gap-6">
                    <div class="md:col-span-2 xl:col-span-3 bg-white p-6 rounded-2xl border border-gray-50">
                        <div class="flex flex-col space-y-6 md:h-full md:justify-between">
                            <div class="flex justify-between">
                                <span class="text-xs text-gray-500 font-semibold uppercase tracking-wider">
                                    Main Account
                                </span>
                                <span class="text-xs text-gray-500 font-semibold uppercase tracking-wider">
                                    Available Funds
                                </span>
                            </div>
                            <div class="flex gap-2 md:gap-4 justify-between items-center">
                                <div class="flex flex-col space-y-4">
                                    <h2 class="text-gray-800 font-bold tracking-widest leading-tight">Derol's Savings
                                        Account</h2>
                                    <div class="flex items-center gap-4">
                                        <p class="text-lg text-gray-600 tracking-wider">**** **** *321</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                                        </svg>
                                    </div>
                                </div>
                                <h2 class="text-lg md:text-xl xl:text-3xl text-gray-700 font-black tracking-wider">
                                    <span class="md:text-xl">$</span>
                                    92,817.45
                                </h2>
                            </div>
                            <div class="flex gap-2 md:gap-4">
                                <a href="#" class="bg-blue-600 px-5 py-3 w-full text-center md:w-auto rounded-lg text-white text-xs tracking-wider font-semibold hover:bg-blue-800">
                                    Transfer Money
                                </a>
                                <a href="#" class="bg-blue-50 px-5 py-3 w-full text-center md:w-auto rounded-lg text-blue-600 text-xs tracking-wider font-semibold hover:bg-blue-600 hover:text-white">
                                    Link Account
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 p-6 rounded-2xl bg-gradient-to-r from-t3-teritiary to-blue-800 flex flex-col justify-between">
                        <div class="flex flex-col">
                            <p class="text-white font-bold">Lorem ipsum dolor sit amet</p>
                            <p class="mt-1 text-xs md:text-sm text-gray-50 font-light leading-tight max-w-sm">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio soluta saepe consequuntur
                                facilis ab a. Molestiae ad saepe assumenda praesentium rem dolore? Exercitationem, neque
                                obcaecati?
                            </p>
                        </div>
                        <div class="flex justify-between items-end">
                            <a href="#" class="bg-blue-800 px-4 py-3 rounded-lg text-white text-xs tracking-wider font-semibold hover:bg-blue-600 hover:text-white">
                                Learn More
                            </a>
                            <img src="assets/calendar.png" alt="calendar" class="w-auto h-24 object-cover">
                        </div>
                    </div>
    
                </div>
        </div> -->
    
        <!-- Operational Dashboard -->
    <div v-if="scopeCheck('view_operational_dashboard')">
      <div class=" mt-4">
        <div class="flex justify-between">
          <h4 class=" text-gray-400 font-medium text-lg sm:text-xl">Assessments Status</h4>
          <div  class="flex items-center">
          <div v-if="filtering" class="flex items-center">
            <span class="mr-4">Date Range :</span> <Datepicker  @update:modelValue="updateStats" v-model="date" range :presetRanges="presetRanges" />
          </div>
          <button v-if="!filtering" class="ml-6 hover:text-t3-teritiary rounded-full" @click="filtering = true">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9 p-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
          </svg>
          </button>
          <button v-if="filtering" class="ml-6 transform rotate-180 hover:text-t3-teritiary rounded-full" @click="clearStats">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9 p-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
          </svg>
          </button>
        </div>
        </div>
        <div class="grid md:grid-cols-2 justify-between gap-12 mt-3 ">
    
          <ul class="grid grid-cols-1 gap-8  " >
            <li class="p-6 bg-white shadow-t3 rounded-lg">
              <p class="text-3xl font-extrabold">{{statistics.total_assessments}}</p>
              <p class="mt-1 text-xl font-medium">Total Assessments</p>
            </li>
            <li class="p-6 bg-white shadow-t3 rounded-lg">
              <p class="text-3xl font-extrabold">{{statistics.status.booked}}</p>
              <p class="mt-1 text-xl font-medium">Booked</p>
            </li>
            <li class="p-6 bg-white shadow-t3 rounded-lg">
              <p class="text-3xl font-extrabold">{{statistics.status.pending}}</p>
              <p class="mt-1 text-xl font-medium">Pending</p>
            </li>     
            <li class="p-6 bg-white shadow-t3 rounded-lg">
              <p class="text-3xl font-extrabold">{{statistics.status.completed}}</p>
              <p class="mt-1 text-xl font-medium">Completed</p>
            </li>
            <li class="p-6 bg-white shadow-t3 rounded-lg">
              <p class="text-3xl font-extrabold">{{statistics.status.cancelled}}</p>
              <p class="mt-1 text-xl font-medium">Cancelled</p>
            </li>
          </ul>
          <div class="grid grid-cols-1  gap-4 ">
            <!-- <div class="bg-white rounded-lg p-6 mt-3">
                <h6 class="mb-3 font-medium">Assessments by Customer Type</h6>
                <DoughnutChart v-if="levelCust" :labelList="customerType" :dato="levelCust" />
            </div> -->
            <div class="bg-white rounded-lg p-6 mt-3">
                <h6 class="mb-3 font-medium">Assessments by Level</h6>
                <BarChart :labelo="levels" :dato="levelNums" />
            </div>
            <div class="bg-white rounded-lg p-6 mt-3">
                <h6 class="mb-3 font-medium">Assessments by Type</h6>
                <BarChart :labelo="customerType" :dato="levelCust" />
            </div>
            <div class="bg-white rounded-lg p-6 mt-3">
                <h6 class="mb-3 font-medium">Assessments Trends</h6>
                <LineChart :labelo="levelMLine" :dato="levelLine" />
            </div>
          </div>
          </div>
        
        </div>
    </div>
    
    <!-- Financiall Dashboard -->
    <div v-if="scopeCheck('view_finance_dashboard')">
      <div class=" mt-4">
        
          <h4 class=" text-gray-400 font-medium text-lg sm:text-xl">Financial Status</h4>
        <div class="grid md:grid-cols-2 justify-between gap-12 mt-3 ">
          <ul class="grid grid-cols-1 gap-8" >
            <li class="p-6 bg-white shadow-t3 rounded-lg">
              <p class="text-3xl font-extrabold">{{finances.total_assessments}} AED</p>
              <p class="mt-1 text-xl font-medium">Total Finances</p>
            </li>
            <li class="p-6 bg-white shadow-t3 rounded-lg">
              <p class="text-3xl font-extrabold">{{finances.status.booked}} AED</p>
              <p class="mt-1 text-xl font-medium">Booked</p>
            </li>
            <li class="p-6 bg-white shadow-t3 rounded-lg">
              <p class="text-3xl font-extrabold">{{finances.status.pending}} AED</p>
              <p class="mt-1 text-xl font-medium">Pending</p>
            </li>     
            <li class="p-6 bg-white shadow-t3 rounded-lg">
              <p class="text-3xl font-extrabold">{{finances.status.completed}} AED</p>
              <p class="mt-1 text-xl font-medium">Completed</p>
            </li>
            <li class="p-6 bg-white shadow-t3 rounded-lg">
              <p class="text-3xl font-extrabold">{{finances.status.cancelled}} AED</p>
              <p class="mt-1 text-xl font-medium">Cancelled</p>
            </li>
          </ul>
          <div class="grid grid-cols-1  gap-4 ">
            <!-- <div class="bg-white rounded-lg p-6 mt-3">
                <h6 class="mb-3 font-medium">Assessments by Customer Type</h6>
                <DoughnutChart v-if="finCust" :labelList="customerType" :dato="levelCust" />
            </div> -->
            <div class="bg-white rounded-lg p-6 mt-3">
                <h6 class="mb-3 font-medium">Finances by Level (AED)</h6>
                <BarChart :labelo="levels" :dato="finNums" />
            </div>
            <div class="bg-white rounded-lg p-6 mt-3">
                <h6 class="mb-3 font-medium">Finances by Type (AED)</h6>
                <BarChart :labelo="customerType" :dato="finCust" />
            </div>
            <div class="bg-white rounded-lg p-6 mt-3">
                <h6 class="mb-3 font-medium">Finances Trends (AED)</h6>
                <LineChart :labelo="finMLine" :dato="finLine" />
            </div>
          </div>
          </div>
        
        </div>
    </div>
        </div>
      </Transition>
        <!-- Assessor Dashboard -->
        <Transition name="slide-fade" appear>
        <div v-if="dashboard==='assessor'" class="absolute top-0 left-0 right-0 bottom-16">
          <div 
      class="bg-white rounded shadow-t3 items-center p-12 relative grid grid-cols-1 md:grid-cols-2 gap-4 2xl:gap-6 mt-6 md:mt-0">
      <div>
        <div  tabindex="0" aria-label="card 1"
          class="focus:outline-none bg-white  space-y-1 dark:bg-t3-nightCard dark:text-white  pb-5">
          <div  class="flex items-center justify-between dark:border-t3-light">
            <img :src="isAssessor.profile_img" alt="profile photo" class=" w-24 h-24 2xl:w-32 2xl:h-32 object-cover rounded-full" />
            <div class="flex items-start justify-between w-full overflow-hidden">
              <div class="2xl:pl-6 pl-4 w-5/6">
                <p tabindex="0" class="focus:outline-none text-xl text-t3 font-medium leading-5">
                  Hello <span class="font-black">{{isAssessor.full_name}}</span>
                </p>
                <a :href="authLink" tabindex="0"
                  class="focus:outline-none text-sm leading-normal pt-2 text-blue-500 dark:text-t3-nightText">
                  Update profile details</a>
                  
              </div>
            </div>
          </div>
          
        </div>
      </div>
  <div >
            <p tabindex="0" class="focus:outline-none text-xl text-t3 font-medium leading-5">
                  Assessments
                </p>
                <div class="flex gap-16 items-center mt-3">
                  <div class="text-center">
                    <h1 class="text-6xl text-t3-secondary font-bold">{{allComp}}</h1>
                    <p class="text-gray-500">Completed</p>
                  </div>
                  <div class="text-center">
                    <h1 class="text-6xl text-t3-secondary font-bold">{{allPend}}</h1>
                    <p class="text-gray-500">Pending</p>
                  </div>
                </div>
          </div>
    </div>
  
  
    <div 
      class="bg-white rounded shadow-t3 items-center p-12 relative divide-y  mt-16 ">
  
      <div class="pb-4">
        <div class=" flex gap-3">
          
          <h1 class="focus:outline-none text-xl text-t3 font-medium mb-3 leading-5 font-semibold m">Today's Assessments
          </h1>
          <div class="w-6 h-6 text-t3 font-semibold bg-gray-200 flex items-center justify-center rounded-full">
            {{todays.length}}
          </div>
        </div>
        <div class="text-xl w-full font-semibold text-center text-t3 my-16" v-if="todays.length === 0 ">
      <img src="../assets/no-assessments.svg" alt="" class="w-32 m-auto">
      <p >There are no assessments for you today.</p>
    </div>
  
        <div class="grid md:grid-cols-2  my-3 gap-3 md:gap-4 2xl:gap-6 w-full">
          <div v-for="assessment in todays" :key="assessment.id" class="p-3 border border-gray-300 rounded-xl flex gap-2 w-128 2xl:w-144">
            <img :src="assessment.customer_image" alt="" class="w-28 h-28 rounded-xl object-cover">
            <div class="w-full h-full flex flex-col">
                <p tabindex="0" class="focus:outline-none text-xl text-t3 font-bold leading-5">
                  {{assessment.certificate_name}}
                </p>
                <p tabindex="0"
                  class="focus:outline-none text-sm leading-normal pt-2 text-gray-500 dark:text-t3-nightText">
                  {{assessment.customer_id}}</p>
                  <div class="mt-auto flex w-full justify-between">
                    <p class="text-t3 font-semibold">
                      {{ assessment.start_time }}
                    </p>
                    <router-link :to="{name:'AssessmentDetails', params:{id: assessment.id} }"
                  class="focus:outline-none text-sm leading-normal pt-2 text-blue-500 dark:text-t3-nightText">
                  View Assessment Details</router-link>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <div class="pb-4 pt-12">
        <h1 class="focus:outline-none text-xl text-t3 font-medium mb-3 leading-5 font-semibold">Requires Actions
        </h1>
        <div class="text-xl w-full font-semibold text-center text-t3 my-16" v-if="reqA.length === 0 && noCom.length === 0 ">
      <img src="../assets/no-assessments.svg" alt="" class="w-32 m-auto">
      <p >There are no assessments awaiting action.</p>
    </div>
    <div class="space-y-3 pt-6">
  
      <router-link :to="{name:'AssessmentDetails', params:{id: assessment} }" v-for="assessment in reqA" :key="assessment" class="flex items-center gap-2  ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-t3-secondary">
      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
    </svg>
      <p>Assessment <span class="text-bold text-blue-500">ID{{ assessment }}</span> is missing your report.</p>
      </router-link>
      <router-link :to="{name:'AssessmentDetails', params:{id: assessment} }" v-for="assessment in noCom" :key="assessment" class="flex items-center gap-2  ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-t3-teritiary">
    <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
  </svg>
  
      <p>Assessment <span class="text-bold text-blue-500">ID{{ assessment }}</span> is missing your comment.</p>
      </router-link>
    </div>
    </div>
      
  </div>
  
  
        </div>
  </Transition>
      </div>


    </div>
    </Transition>
  </template>
  
  <style scoped>
  
  </style>
  
  <script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import BarChart from '@/components/BarChart.vue'
  import LineChart from '@/components/LineChart.vue'
  // import DoughnutChart from '@/components/Doughnut.vue'
  import AssessmentsServices from "../services/AssessmentsServices"
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  import { ref } from 'vue';
  import { watchEffect } from 'vue'
  import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from 'date-fns';
import { RouterLink } from 'vue-router';
  
  
  export default {
    
    name: 'Home',
    components: {
    BarChart,
    LineChart,
    Datepicker,
    RouterLink
},
    methods: {
        ...mapActions({
          getdashboard: 'Statistics/getDashboard',
          getfinance: 'Statistics/getFinances',
          getcustomers: 'Users/getAllCustomers',
          gettypes: 'Settings/getAllCustomerTypes',
          gettodays: 'Assessments/getTodaysAssessment'}
          ),
          scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				

				if(user.is_company){
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			},

          updateStats(event){
            let start = new Date(event[0]).toLocaleDateString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
             let end= new Date(event[1]).toLocaleDateString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
             if (end ==="1970-01-01"){
                  end = null
                  }
                  this.getfinance({startDate:start,endDate:end})
             this.getdashboard({startDate:start,endDate:end})
          },
          clearStats(){
            this.filtering=false
            this.getdashboard({startDate:null,endDate:null})
            this.getfinance({startDate:null,endDate:null})
          }
  
              
     
  
    },
    computed: {
      ...mapState({
          details: state => state.Auth.userDetail,
          customers: state => state.Users.customers,
          statistics: state => state.Statistics.dashboard,
          finances: state => state.Statistics.finances,
          types: state => state.Settings.customer_types,
          todays: state => state.Assessments.todays,
          reqA: state => state.Assessments.reqAction,
          noCom: state => state.Assessments.noComment,
        }),
        ...mapGetters('Users', [
          'customerOnly',
          'staffOnly',
          "ELE",
          "OPE"]),
        isAssessor(){
				let user= JSON.parse(localStorage.getItem('userdetail'))
        if(user.customer_type === 'ope' || user.customer_type === 'ele')
				return user;
        else
        return false
			},
        levelCust(){
          let temp=[]
          for (var i  in this.statistics.customer_type)
            temp.push(this.statistics.customer_type[i])
  
          return temp},
        levelNums(){
          let temp=[]
          for (var i  in this.statistics.level)
            temp.push(this.statistics.level[i])
  
          return temp},
          levelLine(){
          let temp=[]
          for (var i  in this.statistics.line)
            temp.push(this.statistics.line[i])
  
          return temp},
          levelMLine(){
          
          return Object.keys(this.statistics.line)},
          finCust(){
          let temp=[]
          for (var i  in this.finances.customer_type)
            temp.push(this.finances.customer_type[i])
  
          return temp},
        finNums(){
          let temp=[]
          for (var i  in this.finances.level)
            temp.push(this.finances.level[i])
  
          return temp},
          finLine(){
          let temp=[]
          for (var i  in this.finances.line)
            temp.push(this.finances.line[i])
  
          return temp},
          finMLine(){
          
          return Object.keys(this.finances.line)},
      
    },
    created(){
      watchEffect(()=>{
            if(!this.details.is_company && this.details.customer_type === "customer" )
            {
              this.showDash =false
              this.$router.go()
            }
           
           
      })
      if(this.isAssessor){
        if(this.scopeCheck('view_operational_dashboard')||this.scopeCheck('view_financial_dashboard')){
          this.dashboard = 'operational'
          
        }
        else{
          this.dashboard = 'assessor'
        }
              this.gettodays(this.isAssessor.id)
              AssessmentsServices.getAssessmentsFilter (1,12,'completed&status=sealed', '&assessor='+this.isAssessor.id).then((response)=>{
              this.allComp = response.data.count
            }) 
            AssessmentsServices.getAssessmentsFilter (1,12,'pending', '&assessor='+this.isAssessor.id).then((response)=>{
              this.allPend = response.data.count
            }) 
            }
            else{
              this.dashboard = 'operational'
            }
      this.getcustomers()
      this.gettypes().then(()=>{
        this.types.forEach((t)=>{
          this.customerType.push(t.name)
        })
      })
      
      this.getdashboard({startDate:null,endDate:null})
      this.getfinance({startDate:null,endDate:null})
    },
  
      setup() {
        const date = ref();
  
        const presetRanges = ref([
          { 
            label: 'Today', 
            range: [new Date(), new Date()] },
          { 
            label: 'This month', 
            range: [startOfMonth(new Date()), endOfMonth(new Date())] },
          {
            label: 'Last month',
            range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
          },
          { 
            label: 'This year', 
            range: [startOfYear(new Date()), endOfYear(new Date())] },
        ]);
        
        return {
          date,
          presetRanges,
        }
    },
    
      data() {
      return {
        labels: [ 'vue', 'good', 'good', 'good'],
        data: [40, 20, 80, 10],
        levels: [1,2,3,4,5,6],
        customerType: [],
        showDash:true,
        filtering:false,
        filterF:false,
        dashboard:'',
        authLink:  process.env.VUE_APP_ELP_SEC +'://'+ process.env.VUE_APP_AUTH_URL + '/profile/',
        allComp:0,
        allPend:0,
      }
    },
  
      
  }
  </script>
  
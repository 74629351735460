<template>

  <Bar v-if="this.dato"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { watchEffect } from 'vue'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    labelo:{
      type:Array,
      default: () => []
    },
    dato:{
      type:Array
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 250
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {
        
      }
    }
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [ { 
          backgroundColor: ['#212D63', "#00ADEE", "#FA9649", "#990c53"],
          data: this.dato
          } ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  created(){
    this.chartData.labels = this.labelo
    watchEffect(()=>{
      this.chartData.datasets[0].data = this.dato
    })
    
  }
}
</script>
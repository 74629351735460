<template>
    <Line  :chart-options="options"
    :chart-data="data"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height" />
  </template>
  
  <script >
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js'
  import { Line } from 'vue-chartjs'
  import { watchEffect } from 'vue'
  
  ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

  
  export default {
    name: 'LineChart',
    components: {
      Line
    },
    props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    labelo:{
      type:Array,
      default: () => []
    },
    dato:{
      type:Array
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 250
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {
        
      }
    }
  },
    data() {
      return {
        data:{
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Assessments',
        backgroundColor: '#00ADEE',
        data: [40, 39, 10, 40, 39, 80, 40]
      },
    ]
        },
        options:{
    maintainAspectRatio: false
        }
      }
    },
    created(){
      watchEffect(()=>{
      this.data.labels=this.labelo
      this.data.datasets[0].data = this.dato
    })
  }
  }
  </script>
  